@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.logo-slider {
    width: 70%;
    margin-bottom: 60px !important;
    margin-left: auto;
    margin-right: auto;
}

.logo-slider > div > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    
}
.logo-slider img {
    width: 60%;
    align-items: center;
    justify-content: center;
}

@media (max-width: 767px) {
    .logo-slider {
        width: 80%;
        margin: auto;
        padding-bottom: 15px;
        margin-bottom: 60px !important;
    }




}