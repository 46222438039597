.back-office-side-navbar {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: black;
    color: white;
    text-transform: capitalize;
}

.back-office-side-navbar > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 30px;
}

.back-office-side-navbar > div:nth-child(1) > img {
    border-radius: 100px;
    height: 150px;
    width: 150px;
    margin-top: 80px;
}

.back-office-side-navbar > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    font-size: 20px;
}

.back-office-side-navbar > div:nth-child(2) > span {
    margin-top: 15px;
    cursor: pointer;
}