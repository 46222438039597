.decors {
    margin-top: 8%;
}
.decors > div:first-child {
    margin-bottom: 3%;
}

.decors > div:nth-child(2) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 1000px){
    .decors {
        margin-top: 22%;
    }
}

