.decor {
    display: flex;
    flex-direction: row;
    width: 80%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 60px;
    background-color: whitesmoke;
    transition-duration: 0.3s;
    max-height:250px;
    overflow: hidden;
    align-items: flex-end;
}

.decor:hover{
    
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.decor> img {
    width: 40%;
    height: 100%;
}

.decor > div {
    margin: auto;
    font-size: 30px;
    color: black;
}

.decor > div > header{
    font-family: title;
}


@media (max-width: 1000px){

    .decor {
        width: 90%;
        margin-bottom: 20px;
    }

    .decor > div{
        font-size: 18px;
    }

    .decor> img {
        height: 100px;
    }
}