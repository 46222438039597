.footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 400px;
    background-color: #272727;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.footer > div {
    width: 33%;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 20px;
}

.footer > div > img {
    width: 20%;
    margin-left: 20%;
}

.footer > div > div  {
    display: flex;
    flex-direction: row;
    color: white;
    font-size: 18px;
    margin: auto 20px;
}

.footer > div > div > img {
    height: 30px;
    margin: auto 0;
}
.footer > div > div:nth-child(4), .footer > div > div:nth-child(6){
    margin-left: 80px;
    margin-top: -10px;
}


.footer > div > div:nth-child(4) > img, .footer > div > div:nth-child(6) > img{
    height: 17px;
}

.footer > div > div > span {
    margin-left: 10px;
    align-self: center;
}

.footer > div > div > a {
    text-decoration: none;
    color: white;
    margin-left: 10px;
    align-self: center;
}

.footer > div:nth-child(3) > iframe, .footer > div:nth-child(2) > iframe{
    height: 100%;
    width: 90%;
    border-radius: 20px;
    margin-bottom: 15px;
}

@media (max-width: 1000px){
    .footer {
        flex-direction: column;
        height: fit-content;
    }

    .footer > div  {
        width: 90%;
        height: fit-content;
    }

    .footer > div > div {
        margin-top: 15px;
    }

    .footer > div > div:nth-child(4), .footer > div > div:nth-child(6){
        margin-top: 0;
    }
    
    
    .footer > div > div:nth-child(4) > img, .footer > div > div:nth-child(6) > img{
        height: 17px;
    }
}
