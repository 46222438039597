.back-office-home {
    display: flex;
    flex-direction: row;
}
.back-office-home > div:first-child {
    width: 20%;
}

.back-office-content {
    width: 90%;
    margin-top: 7%;
}