.product-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px;
    align-items: center;
    height: fit-content;
    width: fit-content;
}

.product-title > img {
    height: 12px;
    margin: 0 5px;
}
.product-title > a > span, .product-title > span {
    color: black;
    font-size: 20px;
    font-family: luxe;
    /*background-color: lightgray;*/
    padding: 3px 15px;
    border-radius: 15px;
    text-transform: capitalize;
    /*box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;*/
    cursor: pointer;
    transition-duration: 0.3s;
}
.product-title > a > span:hover, .product-title > span:hover {
    color:#a5ce3b;
}

@media (max-width: 1000px){
    .product-title {
        width: fit-content;
    }
    .product-title > a > span, .product-title > span {
        font-size: 13px;
        padding: 2px;
        margin-bottom: 5px;
    }
}
