.about-us {
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
}

.about-us > div:first-child {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-top: 7%;
}

.about-us > div:first-child > div {
    width: 50%;
    margin-left: 70px;
}

.about-us > div:first-child > div > img {
    width: 250px;
}

.about-us > div:first-child > div > p {
    font-family: luxe;
    font-size: 20px;
}

.about-us > div:first-child > div > span {
    font-family: luxe;
    font-weight: bold;
    font-size: 23px;
}

.about-us > div:nth-child(3){
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: auto;
}

.about-us > div:nth-child(3) > div {
    width: 100%;
}

.about-us > div:nth-child(3) > div > img {
    height: 350px;
    
}

.about-us > div:nth-child(3) > div:nth-child(2) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.about-us > div:nth-child(3) > div:nth-child(1) {
    width: 100%;
    font-family: luxe;
    font-size: 20px;
    text-align: justify;
}

.about-us > div:nth-child(3) > div:nth-child(1) > p > span {
    font-weight: bold;
    font-size: 22px;
    color :#a5ce3b; /*#893f1e;*/
}

