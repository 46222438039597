.client-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 85%;
    margin: auto;
}

.client-list > img {
    height: 100px;
    margin: 15px;
    transition-duration: 0.3s;
}


.client-list > img:hover {
    height: 115px;
    
}