.main {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    background-color: lightgrey;
    
}

.main > div {
    width: 60%;
    height: 70%;
    display: flex;
    flex-direction: row;
    background-color: whitesmoke;
    margin:150px  auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
}
.main > div > div{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.main > div > div:nth-child(1) > img{
    width: 20%;
    margin: 10px auto;
}

.main > div > div:nth-child(1) > span:nth-child(2){
    font-size:30px;
    text-align: center;
}

.home-link{
    font-size:30px;
    text-decoration: none;
    text-align: center;
    font-size: 23px;
    padding: 10px 50px;
    color: whitesmoke;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #a5ce3b;
    width: 40%;
    transition: background-color 0.5s ease;
    margin: 40px auto;
}
.home-link:hover{
    background-color: #893f1e;
}

.main > div > div:nth-child(2) > img{
    width: 100%;
    height: 100%;
} 
@media (max-width: 1000px) {

    .main > div {
        width: 90%;
        height: fit-content;
    }
    .home-link{
        font-size:15px;
        text-decoration: none;
        text-align: center;
        padding: 0 ;
        width: 80%;
    }

    .main > div > div:nth-child(1) > span:nth-child(2){
        font-size:20px;
        padding: 5px;
        text-align: center;
    }
    
}
