.products {
    background-color: whitesmoke; 
}

.products > div:nth-child(1) > p{
    background-color: rgba(0, 0, 0, 0.5);
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    letter-spacing: 0.15em;
    width: 40%;
    height: fit-content;
    text-align: left;
    color: whitesmoke;
    font-size:30px;
    padding: 15px;
    text-transform: capitalize;
}

.products > div:nth-child(1) {
    width: 100%;
    height: 50vh;
    background-image: url('../../../public/images/home/products-banner-3.jpg');
    background-size: cover;
    align-content: center;
}

.products > div:nth-child(2) {
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: 100vh;
}

@media (max-width: 1000px) {
    .products > div:nth-child(1) {
        height: 50vh;
    }

    .products > div:nth-child(1) > p {
        width: 85%;
        font-size: 17px;
        margin-top: 0;
        text-align: center;
        border-top-right-radius: 80px;
        border-bottom-right-radius: 80px;
        padding: 20px;
    }
}
