.title{
    display: flex;
    flex-direction: row;
    width: 85%;
    margin: 5px auto;
    align-items: center;
    
}

.title > hr {
    text-transform: uppercase;
    min-height: 1px;
    max-height: 1px;
    width: 43%;
    border: none;
    background-color: rgb(187, 185, 185);
}

.title > header {
    text-transform: uppercase;
    margin: 0 10px;
    font-size: 20px;
    font-family: title;
    text-align: center;
    min-width: fit-content;
}