.product > div {
    display: flex;
    flex-direction: column;
    margin: 10px;
    height: 280px;
    width: 250px;
    transition-duration: 0.2s;
    border-radius: 15px;
    border-radius: 10px;
    
}

.product > div:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    letter-spacing: 0.05em;
}

.product > div > img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 80%;
}

.product > div > header {
    height: 20%;
    text-align: center;
    align-content: center;
    font-size: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    background-color: whitesmoke;
    font-family: luxe;
    border: 1px solid lightgray;
    text-decoration: none;
    color: black;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    
}

a{
    text-decoration: none;
}

@media (max-width: 1000px){
    .product > div {
        flex-direction: column;
        height: 200px;
        width: 200px;
    }
}

